import { Button } from "@mui/material";
import CustomForm from "Components/CustomForm/CustomForm";
import Popup from "Components/Popups/Popup";
import React from "react";

function CouponDetail({ cpn, closePopup }) {
  return (
    <div>
      <Popup title={"Coupon Detail"}>
        <CustomForm
          style={{ width: "100% !important" }}
          width={"100%"}
          // style={{ width:" 100%" }}
          button={
            <div>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
            <div className="flex flex-col">
          <p className=" text-lg font-bold">{cpn.id}</p>
          <br/>

          <p>Percent: {cpn.percent}%</p>
          <br/>
          <p>Count:{cpn.count} </p>
          <p>Expire Date: {cpn.expire_date?cpn.expire_date.toString().slice(0,10):   "Unspecified"}</p>
          <p>Max Count: {cpn.max_count!=0 ?cpn.max_count:"Unspecified"}</p>
          <p>Max Amount: Unpecified </p>
          </div>
        </CustomForm>
      </Popup>
    </div>
  );
}

export default CouponDetail;
