import React, { useEffect, useState } from "react";
import "./MainBodyStructure.css";
import Loading from "../../Scenes/Widgets/Loading";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { lightBlue } from "@mui/material/colors";
import { useDrag, useDrop } from "react-dnd";
import { Button } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { useSelector } from "react-redux";
import { order_company_category } from "Services/Manager/manager_services";
import PublishIcon from "@mui/icons-material/Publish";
import ShortNote from "Components/ShortNote/ShortNote";

const ItemTypes = {
  CARD: "card",
};

function MainBodyStructureOrder({
  title,
  subtitle,
  buttons,
  data,
  handleSetOffset,
  offset,
  isEmpty,
  updateFunction,
  deleteFunction,
}) {
  const token = useSelector((state) => state.token);
  const [table, setTable] = useState(null);
  const [noData, setNoData] = useState(false);
  const [isOrderChanged, setIsOrderChanged] = useState(false);
  const [sortedItems, setSortedItems] = useState(data.data);

  const loadTable = () => {
    setTable(data);
    setSortedItems(data.data)
    setIsOrderChanged(false)

  };
  const handleSendOrderToApi =async () => {
    console.log(sortedItems,"orted items")

    const apiParam = sortedItems.map((category, index)=>{return {id:category.action.id, order: index+1 }})
   const isOrderSuccess = await order_company_category(token,apiParam );
   if(isOrderSuccess)
   setIsOrderChanged(false)
   console.log(isOrderChanged, "order reponse")
  };
  useEffect(() => {
    loadTable();
  }, [data]);



  useEffect(() => {
    setTimeout(() => {
      if (!table) {
        setNoData(true);
      } else {
        if (!table.data) {
          setNoData(true);
        } else {
          if (table.data.length == []) {
            setNoData(true);
          }
        }
      }
      setNoData(true);
    }, 15000);
    setIsOrderChanged(false)
  }, []);

  const rows = [
    { id: 1, col1: "Hello", col2: "World" },
    { id: 2, col1: "DataGridPro", col2: "is Awesome" },
    { id: 3, col1: "MUI", col2: "is Amazing" },
  ];

  const columns = [
    { field: "col1", headerName: "Column 1", width: 150 },
    { field: "col2", headerName: "Column 2", width: 150 },
  ];


  const moveItem = (dragIndex, hoverIndex) => {
    const draggedItem = sortedItems[dragIndex];
    const newSortedItems = [...sortedItems];
    newSortedItems.splice(dragIndex, 1);
    newSortedItems.splice(hoverIndex, 0, draggedItem);
    setSortedItems(newSortedItems);
  };

  const Item = ({ item, index }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: { type: ItemTypes.CARD, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover: (item) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
      <li
        ref={(node) => drag(drop(node))}
        className={`bg-gray-100 p-4 my-2 rounded-md shadow-md w-96 flex justify-between ${
          isDragging ? "opacity-80" : ""
        }`}
        style={{ cursor: "move" }}
      >
        <div className=" rounded-sm p-1 m-1">
        {item.name} 
 
        </div>
        <div>
        <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              // console.log(item)
              updateFunction(item.action)
            }}
          >
            <EditNoteOutlinedIcon />
          </Button>

        <Button
              style={{
                color: "coral",
                marginRight: "10px",
                boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",
  
                padding: "0",
                width: "10px",
              }}
              onClick={() => {
                deleteFunction(item.action)
              }}
            >
              <ClearOutlinedIcon />
            </Button>
            </div>
      </li>
    );
  };
  useEffect(() => {
    const orderChanged = !areArraysEqual(sortedItems.map((f)=>f.id),data.data.map((f)=>f.id) );
    if(orderChanged ){
      setIsOrderChanged(false)
    }
    setIsOrderChanged(true)
    console.log(orderChanged, "ORDER CHANGED")
  }, [sortedItems]);
  return (
    <>
     
      <div className="main-body-str">
        <div className="main-body-header">
          <div className="texts">
            <h2 className="text-xl font-bold">{title}</h2>
            <p>{subtitle}</p>
          </div>
          <div className="buttons">
            {buttons.length != 0 &&
              buttons.map((e) => <div className="main-body-btn">{e}</div>)}
               <Button
               className={"cursor-not-allowed"}
              style={{
                background: "rgb(62, 62, 216)",
                disabled: "rgb(62, 62, 100) !important",
                color: "white",
                marginRight: "10px",
                paddingRight: ".8rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                
                // gap: "5px",
                height: "fit-content",
              }}
              onClick={() => {
                handleSendOrderToApi();
              }}
              sx={{
                "&.Mui-disabled": {
                  background: "#eaeaea !important",
                  // color: "#c0c0c0",
                },
              }}
              disabled={!isOrderChanged}
            >
              <PublishIcon /> SAVE ORDER
            </Button>
          </div>
        </div>
        <div className="flex p-2 justify-center">
        <ShortNote text={"Drag and move the category boxes to change their order in the menu"}/></div>
        <ul className=" mx-8 flex justify-center flex-col items-center">
        {sortedItems.map((item, index) => {
          // console.log(item, "sortable item")
          return <Item key={index} item={item} index={index} />;
        })}
      </ul>
         
      </div>
    </>
  );
}

export default MainBodyStructureOrder;
function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}