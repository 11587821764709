import React, { useState } from "react";
import Popup from "../../../Components/Popups/Popup";
import CustomTextfield from "../../Widgets/CustomTextfield";
import CustomForm from "../../../Components/CustomForm/CustomForm";
import { Button } from "@mui/material";
import Loading from "../../Widgets/Loading";
import ApiResult from "../../Widgets/ApiResult";
import { add_new_branch, addCoupon } from "../../../Services/Manager/manager_services";
import { useSelector } from "react-redux";

function AddCoupon({ closePopup }) {
  const token = useSelector((state) => state.token);

  const [percent, setPercent] = useState(0);
  const [name, setName] = useState("");
  const [max_count, setMaxCount] = useState(0);
  const [max_amount, setMaxAmount] = useState(0);
  const [expire_date, setExpireDate] = useState(Date().toString());

  const [isLoading, setIsLoading] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong, try again!");

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const param = {
        percent,
        max_count,
        max_amount,
        expire_date,
        name
      };
      const res = await addCoupon(token, param);
      setIsLoading(false);
      closePopup(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (!err?.response) {
        setErrorMsg("No Server Response");
      } else if (err.response?.status === 409 || err.response?.status === 401) {
        setErrorMsg(err.response?.data.msg);
      } else {
        setErrorMsg("Couldn't add coupon, try again.");
      }
      setIsError(true);
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      {isError && (
        <ApiResult
          message={`${errorMsg}`}
          handleClose={() => {
            setIsError(false);
          }}
          isSuccess={false}
        />
      )}
      <Popup fullWidth={false} title={"Add New Coupon"}>
        <CustomForm
          handleSubmit={(e) => handelSubmit(e)}
          button={
            <div>
              <Button
                type="submit"
                disabled={
                  String(percent).trim() == "" || String(percent).trim() == "0"
                }
              >
                <p>Add</p>
              </Button>
              <Button onClick={() => closePopup(true)}>
                <p>Close</p>
              </Button>
            </div>
          }
        >
            <div className="span-2">
              <CustomTextfield
                label={"Name"}
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder={"Name"}
                required={true}
              />
            </div>
          <div className="flex-col flex  gap-4 span-2">
        
            <div className="flex  gap-2">
              <CustomTextfield
                label={"Percent"}
                onChange={(e) => setPercent(e.target.value)}
                value={percent}
                placeholder={"%"}
                required={true}
              />
              <CustomTextfield
                label={"Max Count"}
                onChange={(e) => setMaxCount(e.target.value)}
                value={max_count}
                placeholder={"Max Count"}
                // required={true}
              />
            </div>
            <div className="flex  gap-2">
           
              <CustomTextfield
                label={"Max Amount"}
                onChange={(e) => setMaxAmount(e.target.value)}
                value={max_amount}
                placeholder={"branch address"}
                // required={true}
              /> <CustomTextfield

              isReservation={true}
              type="date"
              id="date"
              name="date"
              value={expire_date}
              onChange={(e)=>setExpireDate(e.target.value)}
              // style={inputStyle} 
              label="Expire Date"
            />
            </div>
          </div>
        </CustomForm>
      </Popup>
    </div>
  );
}

export default AddCoupon;
