import MainBodyStructure from "../../../Components/MainBody/MainBodyStructure";
import { fetch_company_branches } from "../../../Services/Admin/admin_services";
import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetch_all_users } from "../../../Services/Admin/admin_services";
import { getStatus } from "../../../Constants/DataaseValues";
// import AddNewBranch from "./AddNewBranch";
import ApiResult from "../../Widgets/ApiResult";
// import UpdateBranch from "./UpdateBranch";
import { setBranches as brancher } from "../../../state";
import { Link } from "react-router-dom";
import { URLs } from "../../../api/URLs";
import { fetchCoupons, getCoupons } from "Services/Manager/manager_services";
import AddCoupon from "./AddCoupon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CouponDetail from "./CouponDetail";

// import AddNewUser from "./AddNewUser";
// import ApiResult from "../../Widgets/ApiResult";
// import UpdateUserProfile from "./UpdateUserProfile";
function CouponsScreen() {
  const dispatch = useDispatch();
  const company = useSelector((state) => state.user.company_id);

  const token = useSelector((state) => state.token);
  const company_id = useSelector((state) => state.user.company_id);
  const [branches, setCoupons] = useState([]);

  //ADD NEW CUSTOMER VARIABLES
  const [isAddBranchSuccessful, setIsAddBranchSuccesful] = useState(false);
  const [isAddBranchFormVisible, setIsAddBranchFormVisible] = useState(false);
  const [updatingBranch, setUpdatingBranch] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  // GET BRANCHES FROM STATE/REDUX
  const branchesFromState = useSelector((state) => state.branches);

  const openAddNewForm = async (open, cancel) => {
    if (open) {
      setIsAddBranchFormVisible(true);
    } else {
      setIsAddBranchFormVisible(false);
      if (!cancel) {
        setIsAddBranchSuccesful(true);
      }
    }
  };
  // UPDATE USER PROFIL
  const [isUpdateBranchSuccesful, setIsUpdateBranchSuccesful] = useState(false);
  const [isUpdateBranchFormVisible, setIsUpdateBranchFormVisible] =
    useState(false);

  const openUpdateForm = async (open, cancel) => {
    if (open) {
      setIsUpdateBranchFormVisible(true);
    } else {
      setIsUpdateBranchFormVisible(false);
      if (!cancel) {
        setIsUpdateBranchSuccesful(true);
      }
    }
  };

  //LOAD COMPANIES FROM STATE OR API
  const getCoupons = async () => {
    try { 
        setIsEmpty(false)
        const list = await fetchCoupons(token, company_id);
        
        if(!list.length) {
            setIsEmpty(true);
            
        }
        const couponList = couponCell(list);

        setCoupons(couponList);

        // dispatch(
        //   brancher({
        //     branches: branchList,
        //   })
        // ); 
    } catch (e) {
      console.log(e, "error in fetch coupons");
    }
  };

  // REFRESH TABLE DATA
  const refreshData = () => {
    setCoupons([]);
    getCoupons();
  };
  useEffect(() => {
    getCoupons();
  }, []);

  useEffect(() => {
    if (isAddBranchSuccessful || isUpdateBranchSuccesful) {
      setCoupons([]);
      getCoupons();
    }
  }, [isAddBranchSuccessful, isUpdateBranchSuccesful]);
  return (
    <div>
      {isAddBranchSuccessful && (
        <ApiResult
          message={`Branch Added Succesfully`}
          handleClose={() => {
            setIsAddBranchSuccesful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isUpdateBranchSuccesful && (
        <ApiResult
          message={`Branch Updated Succesfully`}
          handleClose={() => {
            setIsUpdateBranchSuccesful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}

      {isUpdateBranchFormVisible && (
        <CouponDetail
          closePopup={(close) => openUpdateForm(false, close)}
          cpn={updatingBranch}
        />
      )}
      {isAddBranchFormVisible && (
        <AddCoupon closePopup={(close) => openAddNewForm(false, close)} />
      )}
      <MainBodyStructure
        title={"Coupons"}
        subtitle={"Currently active and inactive coupons of your company."}
        buttons={[
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              openAddNewForm(true);
            }}
          >
            <AddIcon /> Add New Coupon
          </Button>,
          <Button
            style={{
              background: "rgb(62, 62, 216)",
              color: "white",
              marginRight: "10px",
              // paddingLeft: "1rem",
              paddingRight: ".8rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => {
              refreshData();
            }}
          >
            {<RefreshIcon />} REFRESH
          </Button>,
        ]}
        data={{
          header: header((user) => {
            setUpdatingBranch(user);

            openUpdateForm(true);
          }, company_id),
          data: branches,
        }}
        isEmpty={isEmpty}
        // data={usersz?usersz.length?{header:header, data:branchCell(usersz)}: {header:header, data:[]}: {header:header, data:[]}}
      />
    </div>
  );
}

export default CouponsScreen;

const header = (updateFunction, company) => {
  return [
    {
      field: "id",
      headerName: "NO.",
      width: 300,
      headerClassName: "super-app-theme--header", 
      align: "right",
      headerAlign: "right",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "COUPON CODE",
      headerClassName: "super-app-theme--header",
      // width:200,
      flex: 1,

      align: "left",
      padding: "10px",
      headerAlign: "center",
            //   renderCell: (params) => (
            //     <>
            //       <a
            //             target="blank"
            //             href={`${URLs.MENU_ADDRESS}/menu?company=${company}&amp;branch=${params.value.branch_id}`}
            //         style={{
            //           color: "#4C9818",
            //           fontStyle: "italic",
            //           cursor: "pointer",
            //           textDecoration: "none",
            //         }}
            //       >
            //         {params.value.name}
            //       </a>
            //     </>
            //   ),
    },
    {
      field: "created_at",
      headerName: "GIVEN",
      // width: 300,
      flex: 1,

      headerClassName: "super-app-theme--header",
      // flex: 1,
      // width: 200,
      align: "left",

      headerAlign: "center",
    },
    {
        field: "count",
        headerName: "COUNT",
        // width: 300,
        flex: 1,
  
        headerClassName: "super-app-theme--header",
        // flex: 1,
        // width: 200,
        align: "left",
  
        headerAlign: "center",
      },
    {
      field: "percent",
      headerName: "AMOUNT",
      headerClassName: "super-app-theme--header",
      width: 150,

      align: "left",
      headerAlign: "center",
      renderCell: (params)  => (
        <>
          <p style={{ color: params.value == "Suspended" ? "red" : "black" }}>
            {params.value}
          </p>
        </>
      ),
    },
    {
      field: "action",
      headerName: "MANAGE",
      minWidth: 100,
      flex: 1,
      align: "left",
      headerAlign: "left",
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          <Button
            style={{
              color: "rgb(62, 62, 216)",
              marginRight: "10px",
              boxShadow: "0px 0px 2px 0px rgba(128, 126, 126, 0.4)",

              padding: "0",
              width: "10px",
            }}
            onClick={() => {
              updateFunction(params.value);
            }}
          >
            <VisibilityIcon />
          </Button>

          {/* <Button
            style={{
              border: "2px solid coral",
              color: "coral",
              boxShadow: "2px 3px 2px 0px rgba(128, 126, 126, 0.4)",
              padding: "0",
              width: "10px !important",
            }}
            onClick={() => {
              buttonFunctions[3](params.value);
              buttonFunctions[2]();
            }}
          >
            <ClearOutlinedIcon />
          </Button> */}
        </>
      ),
    },
  ];
};

const couponCell = (coupons) => {
  const list = [];
  coupons.map((e) => {
    console.log(e,"ppp  ")
    list.push({
      id: coupons.indexOf(e) + 1,
      name    : e.name,
      created_at: e.created_at.toString().slice(0,10),
      count: e.count,
      percent: `${e.percent}%`,
      action: e,
    });
  });
  return list;
};
