import React, { useEffect, useRef, useState } from "react";
import OrderCard from "./OrderCard";
import {
  clearCanceledOrders,
  updateOrderStatus,
} from "../../../../Services/Cashier/cashier_services";
import { useSelector } from "react-redux";
import ApiResult from "../../../Widgets/ApiResult";
import ClearCanceledOrders from "./ClearCanceledOrders";
import { useReactToPrint } from "react-to-print";
import { Box } from "@mui/material";

function OrdersTab({ orders, handleUpdateList, isLoading, tabLocation }) {
  const [isSuccess, setIsSuccess] = useState(false);
  var componentRef = useRef(null);

  const token = useSelector((state) => state.token);
  const isPrinting = useSelector((state) => state.isPrinting);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const updateOrder = async (id, status, ticketRef) => {
    isLoading(true);
    await updateOrderStatus(token, id, status)
      .then((response) => {
        handleUpdateList(response.msg);
        isLoading(false);
        setIsSuccess(true);
        componentRef = ticketRef;
        if (isPrinting == 1) {
          handlePrint();
        }
      })
      .catch((e) => {
        console.log(e, "error in cancel order");
        isLoading(false);
      });
  };
  const [clearSuccessful, setClearSuccessful] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went wrong");
  const handleClearOrders = async () => {
    isLoading(true);

    await clearCanceledOrders(token)
      .then((response) => {
        isLoading(false);
        handleUpdateList(response.msg);
        setClearSuccessful(true);
      })
      .catch((e) => {
        isLoading(false);

        setIsFail(true);
      });
  };

  const [groupedOrders, setGroupedOrders] = useState({});
  
  useEffect(() => {
    const grouped = orders.reduce((acc, order) => {
      const { payment_id, ...other } = order;
      if (!acc[payment_id]) {
        acc[payment_id] = [];
      }
      acc[payment_id].push(other);
      return acc;
    }, {});

    setGroupedOrders(grouped);
  }, [orders]);
  return (
    <Box
    sx={{
      overflowY: 'scroll', // Enable vertical scrolling
     
      scrollbarColor: "#d3d3d3 transparent", // Light gray with transparent background
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        backgroundColor: "transparent", // Transparent background
        width: 12,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 8,
        minHeight: 24,
        backgroundColor: "#d3d3d3", // Light gray scrollbar color
        border: "4px solid transparent", // Create gap by making border transparent
        margin: "2px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#a0a0a0", // Darker on hover
      },
      "&::-webkit-scrollbar-corner": {
        backgroundColor: "transparent", // Transparent corner
      },
    }}
              >
    <div
      className="flex flex-col overflow-y-scroll"
      style={{ height: "strech" }}
    >
      {isSuccess && (
        <ApiResult
          message={"Order Updated"}
          handleClose={() => {
            setIsSuccess(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {clearSuccessful && (
        <ApiResult
          message={"Cleared Succussfully"}
          handleClose={() => {
            setClearSuccessful(false);
          }}
          isSuccess={true}
        ></ApiResult>
      )}
      {isFail && (
        <ApiResult
          message={"Failed to clear orders"}
          handleClose={() => {
            setIsFail(false);
          }}
        ></ApiResult>
      )}
      {tabLocation == 6 ? (
        <>
          <ClearCanceledOrders
            handleClearOrders={handleClearOrders}
            length={orders.length}
          />
        </>
      ) : null}
      {
        Object.keys(groupedOrders).map((paymentId) => (
          <div key={paymentId} className="flex flex-col gap-10 overflow-x-auto min-h-max ">
            {/* <h2>Payment ID: {paymentId}</h2> */}
            <ul className=" flex ">
              {groupedOrders[paymentId].map((order) => (
                
                <OrderCard
                  order={order}
                  updateOrder={updateOrder}
                  tabLocation={tabLocation}
                />
              ))}
            </ul>
          </div>
        ))
      }
    </div>
    </Box>
  );
}

export default OrdersTab;
